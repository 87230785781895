<template>
  <div class="update-tradein-programs">
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin chương trình thu cũ- đổi mới'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="3">
                    <b-form-group id="input-group-name">
                      <label for="input-code">Tên chương trình:</label>
                      <b-form-input
                        size="sm"
                        id="input-name"
                        v-model="$v.mainModel.name.$model"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-start-date">
                      <label for="input-start-date">Ngày bắt đầu:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="$v.mainModel.startDate.$model"
                      ></date-picker>
                    </b-form-group>

                    <b-form-group id="input-group-end-date">
                      <label for="input-end-date">Ngày kết thúc:</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.endDate"
                      ></date-picker>
                    </b-form-group>
                    <b-form-group>
                      <label style="font-weight: 600" for="input-status"
                        >Trạng thái:</label
                      >
                      <b-form-select
                        class="select-style"
                        size="sm"
                        id="input-3"
                        v-model="mainModel.isActive"
                        :options="listStatus"
                        required
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group>
                      <label
                        style="font-weight: 600"
                        for="input-amortizationExpense"
                        >Phí khấu hao(%):</label
                      >
                      <b-form-input
                        size="sm"
                        id="input-amortizationExpense"
                        v-model="mainModel.amortizationExpense"
                        placeholder="Nhập phí khấu hao"
                        v-mask="mask"
                        class="text-right"
                        @change="onChangeAmortizationExpense"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col cols="5">
                    <label style="font-weight: 600" for="input-description"
                      >Mô tả:</label
                    >
                    <b-form-textarea
                      id="textarea-description"
                      v-model="mainModel.description"
                      placeholder="Nhập mô tả"
                      rows="5"
                    ></b-form-textarea>
                  </b-col>
                  <b-col cols="2"> </b-col>
                  <b-col cols="2"></b-col>
                </b-row>
                <b-row>
                  <b-form-group
                    id="input-group-search"
                    class="col-md-12 mt-4 pr-0"
                  >
                    <label for="input-code">Tìm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <Autosuggest
                          :model="searchProduct"
                          :suggestions="filteredOptionsProduct"
                          :placeholder="'tên sản phẩm'"
                          :limit="10"
                          @select="onSelectedProduct"
                          @change="onInputChangeProduct"
                        />
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          class="select-style"
                          id="select-product"
                          v-model="selectTypeSearch"
                          :options="listTypeSearch"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="fetchProduct"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="p-0">
                        <b-form-select
                          class="select-style"
                          id="select-product"
                          v-model="selectTypeOfProduct"
                          :options="listTypeOfProduct"
                          size="sm"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                          @change="fetchProduct"
                        ></b-form-select>
                      </b-col>
                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldProduct"
                        :items="productTradeInItems"
                        :per-page="10"
                        :current-page="currentPageTable"
                        id="my-table-product"
                      >
                        <template v-slot:cell(type)="row">
                          <span v-if="row.item.type === 1">Sản phẩm</span>
                          <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
                        </template>
                        <template v-slot:cell(buyingPrice)="row">
                          <div style="text-align: end">
                            <span
                              v-text="convertPrice(row.item.buyingPrice)"
                            ></span>
                          </div>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger"
                            @click="deleteItemInArray(row.item.productId)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                      <b-pagination
                        v-show="productTradeInItems.length > 10"
                        v-model="currentPageTable"
                        :total-rows="rowsTable"
                        :per-page="10"
                        aria-controls="my-table-product"
                        align="right"
                      ></b-pagination>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>

                <div class="separator separator-dashed my-5"></div>

                <div>
                  <p class="text-dark font-size-h5 font-weight-bold">
                    Nhóm tiêu chí đánh giá
                  </p>
                </div>

                <b-row>
                  <b-col md-4>
                    <button
                      type="button"
                      class="btn btn-primary font-weight-bolder btn-sm mb-4"
                      @click="showModalAddCriterialGroup"
                    >
                      <i style="font-size: 1rem" class="flaticon2-add-1"></i
                      >Thêm mới
                    </button>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="listCriterialGroup"
                      >
                        <template v-slot:cell(priority)="row">
                          <span
                            v-text="row.item.priority"
                            class="text-center"
                          ></span>
                        </template>
                        <template v-slot:cell(type)="row">
                          <span v-if="row.item.type === 1">Một lựa chọn</span>
                          <span v-else-if="row.item.type === 2"
                            >Nhiều lựa chọn</span
                          >
                        </template>
                        <template v-slot:cell(isShowWeb)="row">
                          <b-form-radio
                            v-model="summaryItem"
                            :value="row.item.id"
                          />
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="mr-2 text-primary"
                            @click="showModalUpdateCriterialGroup(row.item)"
                            v-b-tooltip
                            title="Cập nhật"
                            >mdi-pencil</v-icon
                          >
                          <v-icon
                            small
                            class="text-danger"
                            @click="showDeleteAlert(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  v-if="checkPermission('TRADE_IN_PROGRAM_UPDATE')"
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <b-button
                  v-if="checkPermission('TRADE_IN_PROGRAM_UPDATE')"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
            <b-modal
              ref="add-criterial-modal"
              hide-footer
              title="Thêm mới nhóm tiêu chí"
              id="modal-prevent-closing"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <div class="col-md-12">
                  <div class="row" style="padding-left: 0px">
                    <div class="col-md-3" style="padding-left: 0px">
                      <b-form-group
                        id="input-group-1"
                        label="Thứ tự hiển thị:"
                        label-for="input-1"
                        style="padding: 0px"
                      >
                        <b-form-input
                          id="input-2"
                          size="sm"
                          type="name"
                          v-model="criterialGroupPriority"
                          required
                          placeholder="Nhập thứ tự hiển thị"
                          class="text-right"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-1"
                        label="Tên nhóm:"
                        label-for="input-1"
                        style="padding: 0px"
                      >
                        <b-form-input
                          id="input-2"
                          size="sm"
                          type="name"
                          v-model="criterialGroupName"
                          required
                          placeholder="Nhập tên nhóm tiêu chí"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-3"
                        label="Loại:"
                        label-for="input-3"
                      >
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-4"
                          v-model="selectedCriterialType"
                          :options="listCriterialType"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >-- Chọn loại nhóm tiêu chí
                              --</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" style="padding-left: 0px">
                    <label style="fontweight: 600">Tùy chọn</label>
                  </div>
                  <div class="row" style="padding-left: 0px">
                    <button
                      type="button"
                      class="btn btn-primary font-weight-bolder btn-sm mb-4"
                      @click="addRowCriterial"
                    >
                      <i style="font-size: 1rem" class="flaticon2-add-1"></i
                      >Thêm mới
                    </button>
                    <table
                      class="table table-bordered table-vertical-center table-hover"
                    >
                      <thead>
                        <tr>
                          <th
                            class="name headerTable text-center"
                            style="width: 7%"
                          >
                            Thứ tự hiển thị
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 20%"
                          >
                            Tên
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 18%"
                          >
                            Danh mục linh kiện
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 25%"
                          >
                            Mô tả
                          </th>
                          <th
                            class="code headerTable text-center"
                            style="width: 10%"
                          >
                            Loại toán tử
                          </th>
                          <th
                            class="code headerTable text-center"
                            style="width: 15%"
                          >
                            Giá trị
                          </th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>

                      <tbody
                        v-for="(item, index) in criterialTradeInItems"
                        :key="index"
                      >
                        <CriterialTradeIn
                          v-bind:item="item"
                          v-on:deleteItem="deleteItemInArrayCriterial"
                          v-on:updateItem="updateItemCriterial"
                          v-bind:index="index"
                          v-bind:listCate="listCate"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="createCriterialGroup"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalAddCriterialGroup"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="update-criterial-modal"
              hide-footer
              title="Cập nhật nhóm tiêu chí"
              id="modal-prevent-closing"
              size="xl"
            >
              <v-form ref="form" lazy-validation>
                <div class="col-md-12">
                  <div class="row" style="padding-left: 0px">
                    <div class="col-md-3" style="padding-left: 0px">
                      <b-form-group
                        id="input-group-1"
                        label="Thứ tự hiển thị:"
                        label-for="input-1"
                        style="padding: 0px"
                      >
                        <b-form-input
                          id="input-2"
                          size="sm"
                          type="name"
                          v-model="criterialGroupPriority"
                          required
                          placeholder="Nhập thứ tự hiển thị"
                          class="text-right"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-1"
                        label="Tên nhóm:"
                        label-for="input-1"
                        style="padding: 0px"
                      >
                        <b-form-input
                          id="input-2"
                          size="sm"
                          type="name"
                          v-model="criterialGroupName"
                          required
                          placeholder="Nhập tên nhóm tiêu chí"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        id="input-group-3"
                        label="Loại:"
                        label-for="input-3"
                      >
                        <b-form-select
                          class="select-style"
                          size="sm"
                          id="input-4"
                          v-model="selectedCriterialType"
                          :options="listCriterialType"
                          value-field="id"
                          text-field="name"
                          disabled-field="notEnabled"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null" disabled
                              >-- Chọn loại nhóm tiêu chí
                              --</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row" style="padding-left: 0px">
                    <label style="fontweight: 600">Tùy chọn</label>
                  </div>
                  <div class="row" style="padding-left: 0px">
                    <button
                      type="button"
                      class="btn btn-primary font-weight-bolder btn-sm mb-4"
                      @click="addRowCriterial"
                    >
                      <i style="font-size: 1rem" class="flaticon2-add-1"></i
                      >Thêm mới
                    </button>
                    <table
                      class="table table-bordered table-vertical-center table-hover"
                    >
                      <thead>
                        <tr>
                          <th
                            class="name headerTable text-center"
                            style="width: 7%"
                          >
                            Thứ tự hiển thị
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 20%"
                          >
                            Tên
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 18%"
                          >
                            Danh mục linh kiện
                          </th>
                          <th
                            class="value headerTable text-center"
                            style="width: 25%"
                          >
                            Mô tả
                          </th>
                          <th
                            class="code headerTable text-center"
                            style="width: 10%"
                          >
                            Loại toán tử
                          </th>
                          <th
                            class="code headerTable text-center"
                            style="width: 15%"
                          >
                            Giá trị
                          </th>
                          <th style="width: 5%"></th>
                        </tr>
                      </thead>

                      <tbody
                        v-for="(item, index) in criterialTradeInItems"
                        :key="index"
                      >
                        <CriterialTradeIn
                          v-bind:item="item"
                          v-on:deleteItem="deleteItemInArrayCriterial"
                          v-on:updateItem="updateItemCriterial"
                          v-bind:index="index"
                          v-bind:listCate="listCate"
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateItemCriterialGroup"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="hideModalUpdateCriterialGroup"
                  >Hủy</b-button
                >
              </v-form>
            </b-modal>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(type)="row">
                    <span v-if="row.item.type === 1">Sản phẩm</span>
                    <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
                  </template>

                  <template v-slot:cell(sellingPrice)="row">
                    <div style="text-align: end">
                      <span v-text="convertPrice(row.item.sellingPrice)"></span>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import CriterialTradeIn from '@/view/components/CriterialTradeIn.vue';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from '@/utils/toastHelper';
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_TRADE_IN_PROGRAM,
} from '@/utils/constants';
import xlsx from 'xlsx';
import moment from 'moment';
import { currencyMask, unMaskPrice, convertPrice } from '@/utils/common';
import { checkPermission } from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      summaryItem: '',
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      selectedCategory: [],
      mainModel: {
        id: 0,
        code: null,
        name: null,
        description: null,
        isActive: true,
        startDate: null,
        endDate: null,
        amortizationExpense: 0,
      },
      fields: [
        {
          key: 'priority',
          label: 'Thứ tự hiển thị',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'criterialName',
          label: 'Tiêu chí',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-left',
        },
        {
          key: 'isShowWeb',
          label: 'Hiển thị trên web',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
          tdClass: 'text-center',
        },
        { key: 'actions', label: '' },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      productTradeInItems: [],
      listCriterialGroup: [],
      searchProductFor: '',
      categories: '',
      stores: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Đã khóa',
        },
      ],
      isSearching: false,
      criterialGroupName: '',
      selectedCriterialType: null,
      listCriterialType: [
        {
          id: 1,
          name: 'Một lựa chọn',
        },
        {
          id: 2,
          name: 'Nhiều lựa chọn',
        },
      ],
      criterialTradeInItems: [],
      criterialId: 0,
      criterialGroupId: 0,
      criterialGroupPriority: 1,
      tradeInProgramId: null,
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
        {
          id: 3,
          name: 'Nhóm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      fieldImport: [
        {
          key: 'type',
          label: 'Loại',
        },
        {
          key: 'code',
          label: 'Mã',
        },
        {
          key: 'name',
          label: 'Tên',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
      ],
      currentPage: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      urlExcel: URL_IMPORT_PRODUCT_TRADE_IN_PROGRAM,
      currentPageTable: 1,
      fieldProduct: [
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productCode',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'buyingPrice',
          label: 'Giá thu cũ',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      renderComponent: true,
      mask: currencyMask,
      listCate: [],
    };
  },
  validations: {
    mainModel: {
      name: {
        required,
      },
      startDate: {
        required,
        minValue: minValue(0),
      },
    },
  },
  components: {
    KTCodePreview,
    CriterialTradeIn,
    Autosuggest
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsTable() {
      return this.productTradeInItems.length;
    },
  },
  mounted() {
    let titleName = this.checkPermission('TRADE_IN_PROGRAM_UPDATE')
      ? 'Cập nhật chương trình thu cũ- đổi mới'
      : 'Chi tiết chương trình thu cũ- đổi mới';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chương trình thu cũ- đổi mới', route: '/tradein-programs' },
      {
        title: titleName,
        route: '/tradein-programs/add',
      },
    ]);
    this.tradeInProgramId = this.$route.query.id;
    this.getTradeInProgramById();
  },
  created() {
    this.getListCate();
    this.getCategories();
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    getCategories() {
      ApiService.setHeader();
      ApiService.query('category')
        .then(({ data }) => {
          if (data.status === 1) {
            this.categories = data.data.list_caterogy;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    updateItem(item) {
      this.productTradeInItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.productTradeInItems[index].buyingPrice = item.buyingPrice;
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa nhóm tiêu chí!',
        text: 'Bạn có chắc muốn xóa nhóm tiêu chí này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          for (let i = 0; i < this.listCriterialGroup.length; i++) {
            if (this.listCriterialGroup[i].productId === item.productId) {
              this.listCriterialGroup.splice(i, 1);
            }
          }
        }
      });
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.productTradeInItems.length; i++) {
        if (this.productTradeInItems[i].productId === productId) {
          this.productTradeInItems.splice(i, 1);
        }
      }
    },
    fetchProduct(textSearch) {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-product-category?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        if (textSearch !== this.searchProduct) {
          let nextTextSearch = this.searchProduct;
          this.fetchProduct(nextTextSearch);
        }
      });
    },
    onSelectedProduct(option) {
      this.searchProduct = '';
      let type = 1;
      if (this.selectTypeSearch === 3) {
        type = 2;
      } else if (this.selectTypeSearch === 4) {
        type = 3;
      }
      let item = {
        id: this.$uuid.v4(),
        productId: option.item.id,
        productName: option.item.name,
        productCode: option.item.code,
        sellingPrice: option.item.sellingPrice,
        buyingPrice: 0,
        type: type,
      };

      this.productTradeInItems.push(item);
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      this.debounceInput();
    },
    onBack: function () {
      history.back();
    },
    onSubmit: async function () {
      for (let i = 0; i < this.listCriterialGroup.length; i++) {
        this.listCriterialGroup[i].isDisplayOnWeb = 0;
      }
      const index = (this.listCriterialGroup || []).findIndex(e => e.id === this.summaryItem);
      if (index > -1) {
        this.listCriterialGroup[index].isDisplayOnWeb = 1;
      }

      this.productTradeInItems.forEach((element) => {
        element.buyingPrice = unMaskPrice(element.buyingPrice);
      });
      this.mainModel.startDate = this.mainModel.startDate
        ? moment(this.mainModel.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      this.mainModel.endDate = this.mainModel.endDate
        ? moment(this.mainModel.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : null;
      let data = {
        id: this.tradeInProgramId,
        name: this.$v.mainModel.name.$model,
        description: this.mainModel.description,
        status: this.mainModel.isActive,
        listCriteriaGroup: this.listCriterialGroup,
        listProduct: this.productTradeInItems,
        startDate: this.mainModel.startDate,
        endDate: this.mainModel.endDate,
        amortizationExpense: unMaskPrice(this.mainModel.amortizationExpense),
      };
      ApiService.setHeader();
      ApiService.put('tradeInProgram', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInput: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    showModalAddCriterialGroup() {
      this.criterialGroupName = '';
      this.selectedCriterialType = null;
      this.criterialGroupPriority = 1;
      this.criterialTradeInItems = [];
      this.$refs['add-criterial-modal'].show();
    },
    hideModalAddCriterialGroup() {
      this.$refs['add-criterial-modal'].hide();
    },
    addRowCriterial() {
      this.criterialId++;
      let item = {
        id: this.criterialId,
        priority: 1,
        name: '',
        operatorType: 1,
        amountType: 1,
        amount: 0,
        description: '',
        cateName: '',
        productComponentCategoryId: null,
      };
      this.criterialTradeInItems.push(item);
    },
    updateItemCriterial(item) {
      this.criterialTradeInItems.forEach((element, index) => {
        if (element.id === item.id) {
          this.criterialTradeInItems[index].priority = item.priority;
          this.criterialTradeInItems[index].name = item.name;
          this.criterialTradeInItems[index].operatorType = item.operatorType;
          this.criterialTradeInItems[index].amount = unMaskPrice(item.amount);
          this.criterialTradeInItems[index].description = item.description;
          this.criterialTradeInItems[index].amountType = item.amountType;
          this.criterialTradeInItems[index].cateName = item.cateName;
          this.criterialTradeInItems[index].productComponentCategoryId =
            item.productComponentCategoryId;
        }
      });
    },
    deleteItemInArrayCriterial(id) {
      for (var i = 0; i < this.criterialTradeInItems.length; i++) {
        if (this.criterialTradeInItems[i].id === id) {
          this.criterialTradeInItems.splice(i, 1);
        }
      }
    },
    createCriterialGroup() {
      this.criterialGroupId++;
      let item = {
        id: this.criterialGroupId,
        priority: this.criterialGroupPriority,
        criterialName: this.criterialGroupName,
        type: this.selectedCriterialType,
        listItem: this.criterialTradeInItems,
      };
      this.listCriterialGroup.push(item);
      this.hideModalAddCriterialGroup();
    },
    showModalUpdateCriterialGroup(item) {
      this.selectedCriterialGroupId = item.id;
      this.criterialGroupPriority = item.priority;
      this.criterialGroupName = item.criterialName;
      this.selectedCriterialType = item.type;
      this.criterialTradeInItems = item.listItem;
      this.$refs['update-criterial-modal'].show();
    },
    hideModalUpdateCriterialGroup() {
      this.$refs['update-criterial-modal'].hide();
    },
    updateItemCriterialGroup() {
      this.listCriterialGroup.forEach((element, index) => {
        if (element.id === this.selectedCriterialGroupId) {
          this.listCriterialGroup[index].priority = this.criterialGroupPriority;
          this.listCriterialGroup[
            index
          ].criterialName = this.criterialGroupName;
          this.listCriterialGroup[index].type = this.selectedCriterialType;
          this.listCriterialGroup[
            index
          ].listItem = this.criterialTradeInItems.slice();
        }
      });
      this.hideModalUpdateCriterialGroup();
    },
    getTradeInProgramById: function () {
      ApiService.setHeader();
      ApiService.get(`tradeInProgram/${this.tradeInProgramId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.$v.mainModel.name.$model = data.data.name;
            this.mainModel.description = data.data.description;
            this.mainModel.isActive = data.data.isActive;
            this.mainModel.amortizationExpense = data.data.amortizationExpense;
            this.productTradeInItems = data.data.listProduct;
            this.listCriterialGroup = data.data.listCreteriaGroup;
            const summaryItem = data.data.listCreteriaGroup.find(e => e.isDisplayOnWeb === 1);
            if (summaryItem) this.summaryItem = summaryItem.id;
            this.mainModel.startDate = data.data.startDate
              ? moment(data.data.startDate).format('DD/MM/YYYY')
              : null;
            this.mainModel.endDate = data.data.endDate
              ? moment(data.data.endDate).format('DD/MM/YYYY')
              : null;
          } else {
            this.makeToastFailure('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFailure(response.$error);
        });
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              type: element['Loại'] === 'Sản phẩm' ? 1 : 2,
              name: element['Tên'],
              code: element['Mã'],
              sellingPrice: element['Giá bán'],
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.setHeader();
      ApiService.post('tradeInProgram/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.productTradeInItems.push(element);
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    checkPermission(condition) {
      return checkPermission(condition);
    },
    onChangeAmortizationExpense() {
      this.productTradeInItems.forEach((element) => {
        element.buyingPrice -=
          (element.sellingPrice * this.mainModel.amortizationExpense) / 100;
      });
    },
    getListCate: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productComponentCategory', paramQuery).then(
        ({ data }) => {
          this.listCate = data.data.list_caterogy;
        }
      );
    },
  },
};
</script>

<style lang="scss">
.update-tradein-programs {
  .multi-select-wrapper {
    flex: 1 1 auto;
  }

  .center-label label {
    margin-bottom: 0px;
  }

  .rounded {
    border-radius: 0 !important;
  }

  .form-group label,
  label {
    font-weight: 600;
  }
}
</style>
